import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "./hoa-trustpilot-slider.css";

class HoaTrustpilotSlider extends HTMLElement {
	public constructor() {
		super();

		const nextButton = this.querySelector<HTMLElement>(".swiper-button-next");
		const prevButton = this.querySelector<HTMLElement>(".swiper-button-prev");

		new Swiper(this.querySelector<HTMLElement>(".swiper"), {
			modules: [Navigation],
			direction: "horizontal",
			loop: false,
			slidesPerView: 1,
			spaceBetween: 30,
			mousewheel: {
				forceToAxis: true,
			},
			breakpoints: {
				750: {
					slidesPerView: 2,
				},
				990: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 4,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: nextButton,
				prevEl: prevButton,
			},
		});
	}
}

customElements.define("hoa-trustpilot-slider", HoaTrustpilotSlider);
